
import "./OrderSummary.css";
import { useState } from "react";

import Modal from 'components/Modals'
import linepayImg from 'assets/images/linepay-logo.png'
import { invoiceTypes, modalMsg } from "helpers/const";
import { toUpper } from 'lodash'
import moment from 'moment'
const carrierReg = /^\/[0-9A-Z.+-]{7}$/

const OrderSummary = ({
  invoiceInfo, setInvoiceInfo, orderInfo, orderCancel, checkOut
}) => {

  const [modalStatus, setModalStatus] = useState({ open: false, type: null });
  const [isSubmit, setIsSubmit] = useState(false)

  const changeInvoice = (carrierType) => {
    setInvoiceInfo({...invoiceInfo, carrierType})
  }

  const checkCarrier = () => {
    let pass = true;
    if (invoiceInfo.carrierType === invoiceTypes.carrier) {
      if (!carrierReg.test(invoiceInfo.carrierCode)) pass = false;
    }
    console.log('checkCarrier pass', pass)
    // setDisSubmit(pass)
    return pass;
  }

  const clickBtn = (type) => {
    setModalStatus({open: true, type})
    // modal.openModal(false);
  }

  const clickCancel = () => {
    setModalStatus({open: false, type: null})
  }

  const clickSubmit = (type) => {
    setIsSubmit(true)
    if (type === modalMsg.cancel.value) {
      orderCancel()
    } else {
      checkOut()
    }
  }

  const getDateFormat = () => {
    return orderInfo?.createdAt ? moment(orderInfo?.createdAt).format('YYYY-MM-DD HH:mm') : '';
  }

  return (
    <>
      <div className="is-order-summary">
        <div className="sub-container">
          <div className="contains-order">
            <div className="total-cost">
              <h3>{orderInfo?.productInfo?.name || '-'} </h3>
              <h3>NTD <span className="actual-product-price">{orderInfo?.productInfo?.price || 0}</span></h3>
            </div>
            <div className="total-cost">
              <h4>{`訂單時間: `}<span className="actual-product-price">{`${orderInfo?.productInfo?.machineName || ''} ${getDateFormat()}`}</span></h4>
	      {/* const moment = require('moment') */}
            </div>
            <div className="promo-br"></div>
            <div className="promo-code">
              <h4>電子發票 / E-Invoice</h4>
              <button
                className={
                  `flat-button ${invoiceInfo.carrierType === invoiceTypes.carrier ? 'apply-promo-clicked' : 'apply-promo'}`
                }
                onClick={() => changeInvoice(invoiceTypes.carrier)}
              >
                手機條碼載具 / Mobile carrier
              </button>
              <button
                className={
                  `flat-button ${invoiceInfo.carrierType === invoiceTypes.donate ? 'apply-promo-clicked' : 'apply-promo'}`
                }
                onClick={() => changeInvoice(invoiceTypes.donate)}
              >
                捐贈 / Donate
              </button>

              <div className="enter-promo">
                {invoiceInfo.carrierType === 'carrier' &&
                  <>
                    <h5>載具號碼 / Mobile carrier</h5>
                    <input
                      className={ `select-dropdown ${checkCarrier() ? '' : 'inputErr'}`}
                      type="text"
                      placeholder="/xxxxxxx"
                      value={invoiceInfo?.carrierCode}
                      onChange={(e) =>
                        setInvoiceInfo({...invoiceInfo, carrierCode: toUpper(e.target.value?.replaceAll(/\s/g,''))})
                      }
                  />
                  {!checkCarrier() &&
                    <h5 style={{ color: "#ff2100", margin: '0',  fontWeight: 'bold', }}>
                      格式錯誤 / Invalid format
                    </h5>
                  }
                  </>
                }
              </div>
            </div>
            <div className="promo-br"></div>

            <div className="promo-code">
              <h4>
                付款方式 / Payment methods
                <small>
                  <em style={{ color: "#ff2100", display: 'flex' }}>
                    (僅支援 LINE Pay / Only supports LINE Pay)
                  </em>
                </small>
              </h4>
              <div className="enter-promo">
                <img src={linepayImg} alt="" width={100}/>
              </div>
            </div>
            <div className="promo-br"></div>

            <div className="final-cost">
              <h3>結帳金額 / Total</h3>
              <h4>NTD <span className="actual-product-price">{orderInfo?.productInfo?.price}</span></h4>
            </div>
            <div className="final-checkout">
            <button
                className="flat-button checkout-cancel"
                onClick={() => clickBtn(modalMsg.cancel.value)}
                disabled={isSubmit}
                // orderCancel()
              >
                取消 / Cancel
              </button>
              <button
                className="flat-button checkout"
                onClick={() => clickBtn(modalMsg.payment.value)}
                // checkOut()
                disabled={!checkCarrier() || isSubmit}
              >
                確認付款 / Submit
              </button>

            </div>
          </div>
        </div>
      </div>
      {modalStatus?.open &&
        <Modal {...{modalStatus, clickCancel, clickSubmit}} />
      }
    </>
  );
};
export default OrderSummary;
